const config = {
  auth0: {
    customClaim: 'https://cribl.cloud',
    domain: process.env.GATSBY_AUTH0_DOMAIN,
    clientId: process.env.GATSBY_AUTH0_CLIENTID,
    audience: process.env.GATSBY_AUTH0_AUDIENCE,
    cacheLocation: process.env.GATSBY_AUTH0_CACHELOCATION,
    redirectUri: process.env.GATSBY_AUTH0_REDIRECTURI,
    logoutUri: process.env.GATSBY_AUTH0_LOGOUTURI
  }
};

export default config;
