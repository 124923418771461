import * as React from 'react';
import '../styles/App.less';
import {setUTMCookie} from '../utils/CookieUtil';
import {PageProps} from 'gatsby';
import {useEffect} from 'react';
import config from '../components/config';
import {RedirectLoginOptions} from '@auth0/auth0-react/dist/auth0-context';
import {useAuth} from '../components/Auth/AuthContext';

const Authorize = (props: PageProps) => {
  setUTMCookie(props);

  const {loginWithRedirect} = useAuth();

  useEffect(() => {
    const authParams: RedirectLoginOptions = {
      redirectUri: config.auth0.redirectUri
    };
    const searchParams = new URLSearchParams(window.location.search);

    for (const arg of ['organization', 'invitation']) {
      searchParams.get(arg) ? (authParams[arg] = searchParams.get(arg)) : null;
    }

    loginWithRedirect(authParams);
  });
  return null;
};

export default Authorize;
